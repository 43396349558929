* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: line-through;
}
